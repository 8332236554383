import type { DataFunctionArgs } from "@remix-run/node"
import { EmailSubscribe } from "~/components/email-subscribe"
import {
  ExternalLink,
  ExternalLinkPrimaryButtton,
  InternalLink,
  InternalLinkSecondaryButton,
} from "~/components/link"
import { Section, SectionTitle } from "~/components/typography"
import { loadVariantImageData } from "~/components/variant-image"

export async function loader({ request, context, params }: DataFunctionArgs) {
  return {
    handle: {
      hero: await loadVariantImageData(
        "",
        "72Hpqvw3DVYTC5XfCAC8QjmpjAcaRZQ0sh7DBnLpUjU",
        "hero",
      ),
    },
  }
}

export default function Index() {
  return (
    <div className="flex flex-col lg:text-lg">
      <div className="align-center mt-4 flex flex-wrap justify-center gap-4 px-4">
        <ExternalLinkPrimaryButtton to="https://forms.gle/wtnWtza4YkvaSfKr8">
          Přihláška na letní tábor
        </ExternalLinkPrimaryButtton>
        <InternalLinkSecondaryButton to="/letni-tabor">
          Informace o letním táboře
        </InternalLinkSecondaryButton>
      </div>
      <Section>
        <div className="prose">
          <SectionTitle>Brehoni</SectionTitle>
          <p>Vítej na stránkách Brehonů.</p>
          <p>
            Najdeš zde informace o našem{" "}
            <InternalLink to="/letni-tabor">letním táboře</InternalLink>,
            kalendář <InternalLink to="/akce">nadcházejících akcí</InternalLink>
            , fotogalerie a reportáže z{" "}
            <InternalLink to="/probehlo">akcí už proběhlých</InternalLink>,{" "}
            základní <InternalLink to="/o-nas">informace o oddíle</InternalLink>{" "}
            včetně kontaktu na nás a informace o tom i jak se k nám{" "}
            <InternalLink to="/prihlaska">přidat</InternalLink>.
          </p>
          <p>
            Mimo tyto stránky nás také najdeš na instagramu{" "}
            <ExternalLink to="https://instagram.com/brehonicz">
              @brehonicz
            </ExternalLink>
            . Uvidíme se na příští akci!
          </p>
        </div>
      </Section>
      <Section dark>
        <SubscriptionForm />
      </Section>
    </div>
  )
}

function SubscriptionForm() {
  return (
    <>
      {/* <SectionTitle>Odběr novinek</SectionTitle> */}
      {/* <EmailSubscribe /> */}
    </>
  )
}
